const Constants = global.Constants = {
    events: {
        'LOGIN': { 'event': 'User login', 'category': 'User' },
        'REGISTER': { 'event': 'User register', 'category': 'User' },
    },
    defaultLocale: 'en',
    simulate: {
        FORCE_LANGUAGE: false, // set to "en" etc to specify a language
    },
    // <title>
    titles: {
        home: 'Niall Quinn | Senior Product Designer', // Used by default on all pages
        about: 'Niall Quinn | About', // Used by default on all pages
        blog: 'Blog | Niall Quinn', // Used by default on all pages
        projects: 'Projects | Niall Quinn',
        arcs: 'Arcs | Niall Quinn',
        heroWellbeing: 'Hero Wellbeing | Niall Quinn',
        toneAndSculpt: 'Tone & Sculpt | Niall Quinn',
        battleRoyale: 'Battle Royale | Niall Quinn',
        flagsmith: 'Flagsmith | Niall Quinn',
        cap10: 'Cap10 | Niall Quinn',
        electron: 'Electron | Niall Quinn',
        electronConnect: 'Electron Connect | Niall Quinn',
        siteAssist: 'Site Assist | Niall Quinn',
        detonator: 'Detonator | Niall Quinn',
        revolv: 'Revolv | Niall Quinn',
        purelyCapital: 'Purely Capital | Niall Quinn',
        contact: 'Contact | Niall Quinn',
        contactForm: 'Contact | Niall Quinn',
        contactFormThanksPage: 'Thanks | Niall Quinn',
    },
    // meta:description
    descriptions: {
        // Used by default
        home: 'Digital Product & Interaction Designer',
    },
    // meta:description
    keywords: {
        // Used by default
        home: 'Niall Quinn, designer, web developer, frontend developer, user interface design, ux design, react native, digital, artist',
    },
    pages: {
        home: 'Homepage',
        about: 'About',
        contact: 'Contact',
    },
};

export default Constants;
